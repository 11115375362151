import {useFetch} from "@fmcc/common-hooks";
import React, {useEffect, useState} from "react";
import {AccordionPanel, PrimaryButton, SecondaryButton, TextField} from "@cxc/react-fds-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type ApprovalDashboardRowProps = {
  requestType: string;
  requestId: number;
  requestDate: Date;
  customerName: string;
  accountNumber: string;
  amount: number;
  payableTo?: string;
  documents?: string[];
  status?: string;
};

export default function ApprovalDashboardRow({
                                               requestType,
                                               requestId,
                                               requestDate,
                                               customerName,
                                               accountNumber,
                                               amount,
                                               documents,
                                               payableTo
                                             }: ApprovalDashboardRowProps): JSX.Element {
  const [requestStatus, updateRequestFundsStatus] = useFetch<string>();
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);


  const getDocument = (id: string) => {

    fetch(
      `${process.env.REACT_APP_SERVICES_URL}/v3/nva/documents/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.sessionStorage.access_token}`,
          "Content-type": "application/json",
        },
      }
    ).then(res => res.blob())
      .then(data => URL.createObjectURL(data))
      .then((url) => {
        window.open(url)
      })
      .catch
      (error => {
        console.error(`error fetching document ${error}`)
      })
    ;
  };

  function getUpdateFundsStatusUri(fundId: number, fundsStatus: string) {
    if (fundsStatus === "REJECTED") {
      return `/v3/nva/fundsRequest/${fundId}/reject`;
    } else {
      return `/v3/nva/fundsRequest/${fundId}/approve`;
    }
  }

  const changeFundStatus = (fundId: number, fundsStatus: string) => {
    if (fundsStatus === "REJECTED" && comment.trim().length === 0) {
      setError(true);
    } else {
      const apiPath = getUpdateFundsStatusUri(fundId, fundsStatus);
      updateRequestFundsStatus(`${process.env.REACT_APP_SERVICES_URL}${apiPath}`, {
        body: JSON.stringify({"comment": comment.trim()}),
        method: "PUT",
        headers: {
          Authorization: `Bearer ${window.sessionStorage.access_token}`,
          "Content-type": "application/json",
        },
      });
    }
  };


  useEffect(() => {
    if (requestStatus === "Resolved") {
      window.location.reload();
    } else if (requestStatus === "Unauthorized") {
      window.sessionStorage.removeItem("access_token");
      window.location.reload();
    }
  }, [requestStatus]);

  return (

    <AccordionPanel
      index={requestId}
      titleText={customerName}
      descriptionText={`${requestType} - ${requestDate.toLocaleDateString("en-US", {timeZone: "UTC"})}`}
      className={"bg-ford-gray-fourth"}
    >
      <div className="grid grid-cols-4 gap-4">
        <div>
          <label className={"text-lg"}>Request Type</label>
          <p><FontAwesomeIcon
            icon={requestType === "Funds Request" ? "hand-holding-dollar" : "credit-card"}/> {requestType}</p>
        </div>
        <div>
          <label className={"text-lg"}>Account Number</label>
          <p>{accountNumber}</p>
        </div>
        <div>
          <label className={"text-lg"}>Amount Requested</label>
          <p>{amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}</p>
        </div>
        <div>
          <label className={"text-lg"}>Payable To</label>
          <p>{payableTo}</p>
        </div>
        {window.sessionStorage.user_role === "ROLE_CO" ? null : (
          <div className={"col-span-4"}>
            <div>
              <TextField
                className={"resize"}
                placeholder={"Comments"}
                value={comment}
                label={"Comments"}
                multiline={true}
                onChange={(newValue: any) => setComment(newValue.target.value.slice(0, 255))}>
              </TextField>
            </div>
            <span
              className={`${
                error ? "" : "hidden "
              } -mb-7 ml-5 text-red-500 text-sm`}
            >
                * Comments required to reject
              </span>
          </div>
        )}
        <div className={"row-start-3 col-span-3"}>
          {requestType === "Funds Request" ?
            <div className={"flex flex-wrap"}>{documents?.map((id, key) => {
              return (
                <div key={key} className={"mr-4"}>
                  <div
                    className="cursor-pointer text-ford-blue text-base"
                    onClick={() => {
                      getDocument(id);
                    }}
                  >
                    <FontAwesomeIcon icon="paperclip"/> {`Document ${key.valueOf() + 1}`}
                  </div>
                </div>
              );
            })}</div> : <></>}
        </div>

        {window.sessionStorage.user_role === "ROLE_CO" ? null : (
          <div className={"flex justify-evenly items-center row-start-3 col-start-4"}>
            <SecondaryButton
              title="Reject"
              onClick={() => changeFundStatus(requestId, "REJECTED")}
            />
            <PrimaryButton
              title="Approve"
              onClick={() => changeFundStatus(requestId, "APPROVED")}
            />
          </div>
        )}
      </div>
    </AccordionPanel>
  )
}
