import React, {useMemo, useState} from "react";
import {Accordion} from "@cxc/react-fds-components";
import DropZone from "../../../components/file-drop-zone/DropZone";
import {formatDate} from "../../../components/common/date-format/Formats";
import {Status} from "@fmcc/common-hooks/dist/hooks/useFetch/useFetch";
import {UploadRequestStatusIcon} from "../../../components/common/upload-request-status-icon/UploadRequestStatusIcon";

export default function GdmsReportDashboard(): JSX.Element {
  const [gdmsDate, setGdmsDate] = useState<string>("");
  const [uploadStatus, setUploadStatus] = useState<Status>("NotAsked");

  const getGdmsDate = () => {
    fetch(
      `${process.env.REACT_APP_SERVICES_URL}/v1/gdms/date`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.sessionStorage.access_token}`,
          "Content-type": "application/json",
        },
      }
    ).then(res => res.json())
      .then(date => {
        setGdmsDate(date)
      })
      .catch
      (error => {
        console.error(`error fetching the gdms date ${error}`)
      })
  };

  useMemo(() => {
    return getGdmsDate()
  }, [])

  const submitGdms = (file: File | undefined) => {
    if (file !== undefined) {
      setUploadStatus("Pending");
      const formData = new FormData();
      formData.append("gdmsReport", file);

      fetch(
        `${process.env.REACT_APP_SERVICES_URL}/v1/gdms`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${window.sessionStorage.access_token}`
          },
          body: formData
        }
      ).then((res) => {
          if (res.status === 202) {
            setUploadStatus("Resolved");
          } else {
            setUploadStatus("Rejected");
          }
        }
      )
        .catch
        (error => {
          console.error(`error submitting the gdms report ${error}`)
          setUploadStatus("Rejected")
        })
    }
  }

  return (
    <div className="w-1/3">
      <Accordion
        background={"light"}
        foreground={"light"}>
        <div className="text-ford-blue text-lg mt-5">
          <p className="flex">
            <span className="font-condMed ">Latest GDMS Report uploaded:&nbsp;</span>
            {gdmsDate?.length > 0 ?
              <span className="font-condReg">{formatDate(new Date(gdmsDate))}</span>
              :
              <span className="font-condReg">{"No GDMS Report Uploaded"}</span>
            }

          </p>
        </div>
        <div className="mt-14">
          <DropZone label="Please upload today's GDMS Report." onClick={submitGdms} uploadRequestStatus={uploadStatus}
                    type={"GDMS"}/>
        </div>
      </Accordion>
      <div className={"mt-28"}>
        {UploadRequestStatusIcon(uploadStatus)}
      </div>
    </div>
  );
}
