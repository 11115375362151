import {CustomerSearchResultProp} from "./CustomerSearchResultPanel";
import {useState} from "react";
import {Icon, PrimaryButton, TextField} from "@cxc/react-fds-components";

export default function CustomerBankAccount({searchResult}: CustomerSearchResultProp): JSX.Element {
  const [editBankAccount, setEditBankAccount] = useState<boolean>(false);
  const [bankAccountNumber, setBankAccountNumber] = useState<string>(searchResult.bankAccountNumber || "");
  const [newBankAccountNumber, setNewBankAccountNumber] = useState<string>(searchResult.bankAccountNumber || "");
  const regex = new RegExp(/^\d{4}$/i);

  function performUpdateBankAccountAction() {
    if (editBankAccount) {
      fetch(`${process.env.REACT_APP_SERVICES_URL}/v2/nva/customer`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${window.sessionStorage.access_token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          {
            dealerFinanceAccountNumber: searchResult.dealerFinanceAccountNumber,
            bankAccountNumber: newBankAccountNumber
          }
        )
      }).then(async response => {
        if (response.status === 200) {
          setEditBankAccount(false)
          if (newBankAccountNumber) {
            setBankAccountNumber(newBankAccountNumber)
          }
        } else {
          setEditBankAccount(true);
        }
      });
    }
  }

  return (
    <>
      <div className="flex justify-between items-start leading-9 mt-4 mb-1">
        <div className={"flex w-full flex-col items-right"}>
          <span className="text-base ford-font-med text-black">Customer Bank Account</span>
          {bankAccountNumber ?
            <div className="ford-font-light mt-5 text-base text-ford-gray">
              Payment method on file: ****{bankAccountNumber}
            </div>
            :
            <div className="font-light mt-5 text-base text-ford-gray">
              Payment method on file: <span className={""}> NO ACCOUNT ON FILE</span>
            </div>
          }
        </div>
        <span
          className="text-base font-regular text-ford-blue cursor-pointer"
          onClick={() => {
            setEditBankAccount(!editBankAccount)
          }}
        >{!editBankAccount ? <div className="fds-decoration--underline">Edit</div> :
          <Icon name="clear"/>}</span>
      </div>

      {editBankAccount &&
          <>
              <TextField
                  onChange={(event) => {
                    setNewBankAccountNumber(event.target.value)
                  }}
                  value={newBankAccountNumber || ""}
                  patternMismatchMessage={"Please enter only last 4 digits of bank account number."}
                  pattern={/^\d{4}$/i}
                  validationMode={"onChange"}
              />
              <div className="mt-2 text-xs ford-font-light text-ford-gray-second">
                  <span>To change the payment method on file, please input the last four digits of the customer’s preferred bank account number.</span>
              </div>
              <div className="flex justify-end items-center space-x-8 mt-4">
                  <PrimaryButton
                      title="Save"
                      disabled={!regex.test(newBankAccountNumber || "")}
                      outlined={true}
                      onClick={() => {
                        performUpdateBankAccountAction();
                      }}
                  >
                  </PrimaryButton>
              </div>
          </>
      }
    </>
  )
}
