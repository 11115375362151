import {Accordion} from "@cxc/react-fds-components";
import ProcessingDashboardRow from "../../../components/dashboard-row/ProcessingDashboardRow";
import React from "react";
import {RequestSummary} from "../RequestSummary";

type RequestProcessingAccordionProps = {
  requestData: RequestSummary[] | undefined;
  active: string;
}

export default function RequestProcessingAccordion({
                                                     requestData,
                                                     active,
                                                   }: RequestProcessingAccordionProps
): JSX.Element {
  return (
    <Accordion
      background={"light"}
      foreground={"light"}
    >
      {requestData?.map((body, key) => {
        return (
          <ProcessingDashboardRow
            activeTab={active}
            key={key}
            requestId={body.request.id}
            background={key % 2 === 0}
            requestType={body.request.requestType}
            requestDate={new Date(body.request.requestDate)}
            customerName={body.request.businessName}
            accountNumber={body.request.dealerFinanceAccountNumber}
            amount={body.request.amountRequested}
            documents={body.documents}
            processing={body.processing}
            status={body.request.status}
            payableTo={body.request.payableTo}
          />
        );
      })}
    </Accordion>
  )
}



