import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AuthenticatedRoute from "./components/common/authenticated-route/AuthenticatedRoute";
import Dashboard from "./components/common/dashboard/Dashboard";
import Header from "./components/common/header/Header";

function App() {
  return (
    <Router>
      <AuthenticatedRoute>
        <Header/>
        <Routes>
          <Route path="/" element={<Dashboard/>}/>
        </Routes>
      </AuthenticatedRoute>
    </Router>
  );
}

export default App;
