import React, {useEffect, useState} from "react";
import {useFetch} from "@fmcc/common-hooks";
import {RequestSummary} from "../RequestSummary";
import RequestProcessingAccordion from "./RequestProcessingAccordion";

export default function RequestProcessingDashboard(): JSX.Element {
  const [status, getAllRequests, responseBody] =
    useFetch<RequestSummary[]>();
  const [sortOrder, setSortOrder] = useState(false);
  const [requestData, setRequestData] = useState<RequestSummary[]>();
  const [active, setActive] = useState("");

  const tabHandler = React.useCallback(
    (active: string) => {
      setActive(active);
      let filteredRequests: RequestSummary[] = [];
      responseBody?.forEach((element) => {
        if (element.request.status.toLowerCase() === active) {
          filteredRequests?.push(element);
        }
      });
      setRequestData(filteredRequests);
    },
    [responseBody]
  );

  const sortRequests = () => {
    if (sortOrder) {
      requestData?.sort((a, b) =>
        new Date(a.request.requestDate) <
        new Date(b.request.requestDate)
          ? -1
          : 1
      );
      setSortOrder(!sortOrder);
    } else {
      requestData?.sort((a, b) =>
        new Date(a.request.requestDate) >
        new Date(b.request.requestDate)
          ? -1
          : 1
      );
      setSortOrder(!sortOrder);
    }
  };

  useEffect(() => {
    if (status === "Resolved") {
      setRequestData(responseBody!);
      if (window.sessionStorage.getItem("active")) {
        tabHandler(window.sessionStorage.getItem("active")!);
      } else {
        tabHandler("approved");
      }
    } else if (status === "Unauthorized") {
      window.sessionStorage.removeItem("access_token");
      window.location.reload();
    }
  }, [status, responseBody, tabHandler]);

  useEffect(() => {
    getAllRequests(
      `${process.env.REACT_APP_SERVICES_URL}/v1/requests/processing`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.sessionStorage.access_token}`,
        },
      }
    )
  }, [getAllRequests]);

  useEffect(() => {
    if (requestData !== undefined) {
      sortRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData]);

  return (
    <div className="mt-16 mb-6 text-sm">
      <div className={"flex justify-between"}>
        <p className="text-left pb-8 fordngbs-antenna-cond-medium">
        <span
          className={`p-4 ${
            active === "approved" ? "border-b-2 border-primary" : ""
          } cursor-pointer`}
          onClick={() => {
            tabHandler("approved");
          }}
        >
          Approved Requests
        </span>
          <span
            className={`p-4 ${
              active === "completed" ? "border-b-2 border-primary" : ""
            } cursor-pointer`}
            onClick={() => {
              tabHandler("completed");
            }}
          >
          Completed Requests
        </span>
          <span
            className={`p-4 ${
              active === "rejected" ? "border-b-2 border-primary" : ""
            } cursor-pointer`}
            onClick={() => {
              tabHandler("rejected");
            }}
          >
          Rejected Requests
        </span>
        </p>
        <p className="text-right text-primary mb-4">
          <span className="font-light">Sort By:{" "}</span>
          <span
            onClick={sortRequests}
            className={`${sortOrder ? "hidden" : "text-primary"} font-medium cursor-pointer`}
          >
          Oldest to Newest
        </span>
          <span
            onClick={sortRequests}
            className={`${sortOrder ? "text-primary" : "hidden"} font-medium cursor-pointer`}
          >
          Newest to Oldest
        </span>
        </p>
      </div>
      {active === "approved" && requestData?.length === 0 && status === "Resolved" ?
        <div className="text-center text-ford-blue mt-36">
          <div className="text-4xl ford-font-med font-bold mb-6"> You are all caught up!</div>
          <div className="text-xl"> There are no pending requests.</div>
        </div>
        :
        requestData?.length === 0 ? (
          <p className="mt-36 text-2xl">No data</p>
        ) : null}

      {active === "approved" &&
          <RequestProcessingAccordion
              requestData={requestData}
              active={active}
          />}

      {active === "completed" &&
          <RequestProcessingAccordion
              requestData={requestData}
              active={active}
          />}

      {active === "rejected" &&
          <RequestProcessingAccordion
              requestData={requestData}
              active={active}
          />}
    </div>
  );
}
