import {useFetch, useFetchForBlob} from "@fmcc/common-hooks";
import React, {useEffect, useState} from "react";
import {AccordionPanel, PrimaryButton, SecondaryButton, TextField} from "@cxc/react-fds-components";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faMoneyBill, faMoneyBillTrendUp, faPaperclip, fas} from '@fortawesome/free-solid-svg-icons'
import {far, faThumbsUp} from '@fortawesome/free-regular-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

library.add(fas, far, faPaperclip, faMoneyBill, faMoneyBillTrendUp, faThumbsUp)

type ProcessingDashboardRowProps = {
  requestType: string;
  requestId: number;
  requestDate: Date;
  customerName: string;
  accountNumber: string;
  amount: number;
  payableTo: string;
  documents?: string[];
  background: boolean;
  status?: string;
  processing: {
    mloRequestProcessing: {
      processingComment?: string;
      actionedBy: string;
      actionedByDate: string;
    };
    coRequestProcessing: {
      completeComment?: string;
      completedBy: string;
      completedByDate: string;
    };
  };
  activeTab?: string;
};

export default function ProcessingDashboardRow({
                                                 requestType,
                                                 requestId,
                                                 requestDate,
                                                 customerName,
                                                 accountNumber,
                                                 amount,
                                                 documents,
                                                 processing,
                                                 activeTab: active = "",
                                                 status,
                                                 payableTo
                                               }: ProcessingDashboardRowProps): JSX.Element {
  const [requestStatus, setRequestStatus] = useFetch<string>();
  const [documentStatus, getOneDocument, documentResponse] = useFetchForBlob<Blob>();
  const [comment, setComment] = React.useState("");
  const [error, setError] = useState(false);

  const getDocument = (id: string) => {
    getOneDocument(
      `${process.env.REACT_APP_SERVICES_URL}/v3/nva/documents/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.sessionStorage.access_token}`,
          "Content-type": "application/json",
        },
      }
    );
  };

  function showDocument(file: Blob) {
    window.open(URL.createObjectURL(file));
  }

  const completeRequestStatus = async (requestId: number) => {
    await setRequestStatus(
      `${process.env.REACT_APP_SERVICES_URL}${requestType === "Funds Request"
        ? `/v3/nva/fundsRequest/${requestId}/complete`
        : `/v1/payments/${requestId}/complete`}`,
      {
        body: JSON.stringify({"comment": comment.trim()}),
        method: "PUT",
        headers: {
          Authorization: `Bearer ${window.sessionStorage.access_token}`,
          "Content-type": "application/json",
        },
      }
    );
  };

  const rejectPaymentRequestStatus = async (requestId: number) => {
    if (comment.trim().length === 0) {
      setError(true);
    } else {
      await setRequestStatus(`${process.env.REACT_APP_SERVICES_URL}/v1/payments/${requestId}/reject`, {
        body: JSON.stringify({"comment": comment.trim()}),
        method: "PUT",
        headers: {
          Authorization: `Bearer ${window.sessionStorage.access_token}`,
          "Content-type": "application/json",
        }
      });
    }
  }

  useEffect(() => {
    if (documentStatus === "Resolved" && documentResponse) {
      showDocument(documentResponse);
    }
  }, [documentStatus, documentResponse]);

  useEffect(() => {
    if (requestStatus === "Resolved") {
      window.sessionStorage.setItem("active", active);
      window.location.reload();
    } else if (requestStatus === "Unauthorized") {
      window.sessionStorage.removeItem("access_token");
      window.location.reload();
    }
  }, [requestStatus, active]);

  useEffect(() => {
    setComment(processing?.mloRequestProcessing?.processingComment ?? "")
  }, [processing?.mloRequestProcessing?.processingComment]);

  useEffect(() => {
    setComment(processing?.coRequestProcessing?.completeComment ?? "")
  }, [processing?.coRequestProcessing?.completeComment])

  function hasProcessingComment() {
    return (processing?.mloRequestProcessing?.processingComment !== "" && processing?.mloRequestProcessing?.processingComment !== undefined);
  }

  function hasCompleteComment() {
    return (processing?.coRequestProcessing?.completeComment !== "" && processing?.coRequestProcessing?.completeComment !== undefined);
  }

  return (
    <AccordionPanel
      index={requestId}
      titleText={customerName}
      descriptionText={`${requestType} - ${requestDate.toLocaleDateString("en-US", {timeZone: "UTC"})}`}
      className={"bg-ford-gray-fourth"}
    >
      <div className={"grid grid-cols-4 gap-4"}>
        <div>
          <label className={"text-lg"}>Request Type</label>
          <p><FontAwesomeIcon
            icon={requestType === "Funds Request" ? "hand-holding-dollar" : "credit-card"}/> {requestType}</p>
        </div>
        <div>
          <label className={"text-lg"}>Account Number</label>
          <p>{accountNumber}</p>
        </div>
        <div>
          <label className={"text-lg"}>Amount Requested</label>
          <p>{amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}</p>
        </div>
        {requestType === "Funds Request" ?
          <div>
            <>
              <label className={"text-lg"}>Payable To</label>
              <p>{payableTo}</p>
            </>
          </div> : <></>}
        {(status === "APPROVED" &&
            <div className={"col-span-4"}>
                <div>
                    <TextField
                        className={"resize"}
                        placeholder={"Comments"}
                        label={"Comments"}
                        value={comment}
                        multiline={true}
                        onChange={(newValue: any) => setComment(newValue.target.value.slice(0, 255))}>
                    </TextField>
                    <span
                        className={`${
                          error ? "" : "hidden "
                        } -mb-7 ml-5 text-red-500 text-sm`}
                    >
                * Comments required to reject
              </span>
                </div>
            </div>
        )}
        {window.sessionStorage.user_role === "ROLE_MLO" ? null :
          <div className={"col-span-4"}>
            {(requestType === "Payment Request" && status === "APPROVED") ? <></> :
              <>
                <label className={"text-lg leading-9"}>Saved Comments</label>
                {hasProcessingComment() &&
                    <div className="mb-2">
                        <p className={"flex ford-font-light text-ford-blue"}>
                          {processing?.mloRequestProcessing?.processingComment}
                        </p>
                        <label className={"text-sm ford-font-light"}>{
                          processing?.mloRequestProcessing?.actionedByDate ?? ""
                        } • {processing?.mloRequestProcessing?.actionedBy}
                        </label>
                    </div>
                }
                {hasCompleteComment() &&
                    <div className="mb-2">
                        <p className={"flex ford-font-light text-ford-blue"}>
                          {processing?.coRequestProcessing?.completeComment}
                        </p>
                        <label className={"text-sm ford-font-light"}>
                          {processing?.coRequestProcessing.completedByDate ?? ""
                          } • {processing?.coRequestProcessing.completedBy}
                        </label>
                    </div>
                }
                {!hasProcessingComment() && !hasCompleteComment() &&
                    <p className="flex ford-font-light text-ford-blue mb-2">No Comments Included
                    </p>
                }
              </>
            }
          </div>
        }
      </div>
      <div className={"flex justify-between mt-4"}>
        {
          processing?.mloRequestProcessing && <label className={"text-sm ford-font-light"}> <FontAwesomeIcon
                icon="thumbs-up"/> {status?.toLowerCase().replace(/^./, status[0].toUpperCase())}
                &nbsp;by: {processing?.mloRequestProcessing?.actionedBy} {processing?.mloRequestProcessing?.actionedByDate}
            </label>
        }
      </div>
      <div className={"grid grid-cols-4 gap-4"}>
        <div className={"grid mt-4 " + (active === 'completed' || active === 'rejected' ? "col-span-4" : "col-span-3")}>
          {requestType === "Funds Request" ?
            <div className={"flex flex-wrap"}>{documents?.map((id, key) => {
              return (
                <div key={key} className={"mr-4"}>
                  <div
                    className="cursor-pointer text-ford-blue text-base"
                    onClick={() => {
                      getDocument(id);
                    }}
                  >
                    <FontAwesomeIcon icon="paperclip"/> {`Document ${key.valueOf() + 1}`}
                  </div>
                </div>
              );
            })}</div> : <></>}
        </div>
        {active === "completed" || active === "rejected" ? null : (
          <div className={"col-start-4 flex justify-end mb-2 w-full space-x-12"}>
            {
              requestType === "Payment Request" &&
                <SecondaryButton title="Reject" onClick={() => rejectPaymentRequestStatus(requestId)}/>
            }
            <PrimaryButton
              title="Complete"
              className="fordngbs-antenna-re text-lg text-ford-blue underline hover:no-underline hover:bg-transparent"
              onClick={() => completeRequestStatus(requestId)}
            />
          </div>
        )}


      </div>
    </AccordionPanel>
  )
}
