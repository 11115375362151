import {PrimaryButton, TextField} from "@cxc/react-fds-components";
import {useState} from "react";
import CustomerSearchResultPanel from "./CustomerSearchResultPanel";

export type CustomerByDealerFinanceAccount = {
  businessName?: string,
  dealerFinanceAccountNumber?: string,
  bankAccountNumber?: string,
}

export default function CustomerInformationDashboard(): JSX.Element {
  const [dealerFinanceAccountNumber, setDealerFinanceAccountNumber] = useState("");
  const [searchResult, setSearchResult] = useState<CustomerByDealerFinanceAccount>();

  function performSearchAction() {
    if (dealerFinanceAccountNumber) {
      fetch(`${process.env.REACT_APP_SERVICES_URL}/v2/nva/customer/search?dealerFinanceAccountNumber=${dealerFinanceAccountNumber}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.sessionStorage.access_token}`,
        },
      }).then(async response => {
        setSearchResult(undefined);
        if (response.status === 200) {
          const searchResult = await response.json();
          setSearchResult(searchResult)
        } else {
          setSearchResult(undefined);
        }
      });
    }
  }

  return (
    <div className="fds-app w-1/2">
      <div className="pb-20">
        <p className="pt-8 pb-4">
          <span
            className="text-base text-ford-gray my-4">Search for a customer by entering a Dealer Finance Account Number:</span>
        </p>

        <TextField
          name="Dealer Finance Account Number"
          onBlur={(event) => {
            setDealerFinanceAccountNumber(event.target.value)
          }}
          value={dealerFinanceAccountNumber || ""}
          onChange={(event) => {
            setDealerFinanceAccountNumber(event.target.value)
          }}
          pattern={/^0000\d{6}$/i}
          required
        />
        <div className="float-right py-2">
          <PrimaryButton title="Search" onClick={() => {
            performSearchAction()
          }}/>
        </div>
      </div>

      {searchResult && <CustomerSearchResultPanel searchResult={searchResult}/>}
      <div className="pb-12"></div>
    </div>
  )
}
