import {Status} from "@fmcc/common-hooks/dist/hooks/useFetch/useFetch";
import {ErrorToast, SuccessToast} from "../toast/Toast";

export function UploadRequestStatusIcon(status: Status): JSX.Element {
  switch (status) {
    case "Resolved":
      return <SuccessToast message={"Upload Successful"}/>;
    case "Rejected":
      return <ErrorToast message={"Upload Failed"}/>;
    default:
      return <></>;
  }
}