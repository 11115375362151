import ApprovalDashboardRow from "../../../components/dashboard-row/ApprovalDashboardRow";
import React, {useEffect, useState} from "react";
import {useFetch} from "@fmcc/common-hooks";
import {Accordion} from "@cxc/react-fds-components";
import {RequestSummary} from "../RequestSummary";


export default function RequestApprovalDashboard(): JSX.Element {
  const [status, getAllRequestFunds, data] = useFetch<RequestSummary[]>();
  const [sortOrder, setSortOrder] = useState(false);
  const [requestData, setRequestData] = useState<RequestSummary[]>();


  const sortRequests = () => {
    if (sortOrder) {
      requestData?.sort((a, b) =>
        new Date(a.request.requestDate) <
        new Date(b.request.requestDate)
          ? -1
          : 1
      );
      setSortOrder(!sortOrder);
    } else {
      requestData?.sort((a, b) =>
        new Date(a.request.requestDate) >
        new Date(b.request.requestDate)
          ? -1
          : 1
      );
      setSortOrder(!sortOrder);
    }
  };

  useEffect(() => {
    if (status === "Resolved") {
      setRequestData(data!)
    } else if (status === "Unauthorized") {
      window.sessionStorage.removeItem("access_token");
      window.location.reload();
    }
  }, [status, data])

  useEffect(() => {
    if (requestData !== undefined) {
      sortRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData]);


  useEffect(() => {
    getAllRequestFunds(`${process.env.REACT_APP_SERVICES_URL}/v3/nva/funds`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.sessionStorage.access_token}`,
      },
    });
  }, [getAllRequestFunds]);

  return (
    <div className="mt-16 mb-6 text-sm">
      <p className="text-right text-primary mb-4">
        <span className="font-light">Sort By:{" "}</span>
        <span
          onClick={sortRequests}
          className={`${sortOrder ? "hidden" : "text-primary"} font-medium`}
        >
          Oldest to Newest
        </span>
        <span
          onClick={sortRequests}
          className={`${sortOrder ? "text-primary" : "hidden"} font-medium`}
        >
          Newest to Oldest
        </span>{" "}
      </p>
      {requestData !== undefined && requestData.length > 0 ? requestData?.map((body, key) => {
          return (
            <Accordion
              background={"light"}
              foreground={"light"}
              key={key}
            >
              <ApprovalDashboardRow
                key={key}
                requestType={"Funds Request"}
                requestId={body.request.id}
                requestDate={new Date(body.request.requestDate)}
                customerName={body.request.businessName}
                accountNumber={body.request.dealerFinanceAccountNumber}
                amount={body.request.amountRequested}
                payableTo={body.request.payableTo}
                documents={body.documents}
                status={body.request.status}
              />
            </Accordion>
          );
        }) :
        status === "Resolved" &&
          <div className="text-center text-ford-blue mt-36">
              <div className="text-4xl ford-font-med font-bold mb-6"> You are all caught up!</div>
              <div className="text-xl"> There are no pending requests.</div>
          </div>
      }
    </div>
  );
}
