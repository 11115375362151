import React from "react";
import {ADFS_LOGIN_PAGE_URL, useAdfsAuthentication,} from "./useAuthentication";

type Props = {
  children: React.ReactNode;
};

export default function AuthenticatedRoute({children}: Props): JSX.Element {
  const authStatus = useAdfsAuthentication();

  switch (authStatus) {
    case "idle":
      return <></>;
    case "redirecting":
      window.location.href = ADFS_LOGIN_PAGE_URL;
      return <></>;
    case "authorizing":
      return (
        <div
          style={{
            textAlign: "center",
            alignItems: "center",
            height: "100vh",
            display: "flex",
            width: "100vw",
            backgroundColor: "#282c34",
            color: "white",
          }}
        >
          <span style={{width: "100%", fontSize: "30px"}}>
            Authenticating...
          </span>
        </div>
      );
    case "authorized":
      return <>{children}</>;
    case "not_authorized":
      return (
        <p style={{fontSize: "20px", marginTop: "20px"}}>
          You are not authorized to use this service. Please contact{" "}
          <a href="mailto:jfabisze@ford.com">Julia Fabiszewski</a> to request access.
        </p>
      );
  }
}
