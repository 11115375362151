import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DropZone from "../../../components/file-drop-zone/DropZone";
import Select from "../../../components/common/select/Select";
import {monthOptions} from "../upload-portal/StatementUpload";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Status} from "@fmcc/common-hooks/dist/hooks/useFetch/useFetch";
import {CustomerByDealerFinanceAccount} from "./CustomerInformationDashboard";
import {Icon} from "@cxc/react-fds-components";

type Statement = {
  file: string;
  month: number;
  year: number;
  customerId: number;
  fileType: string;
  id: number;
}

interface CustomerStatementsProps {
  searchResult: CustomerByDealerFinanceAccount;
  uploadStatus: Status;
  setUploadStatus: Dispatch<SetStateAction<Status>>;
}

export default function CustomerStatements({
                                             searchResult,
                                             uploadStatus,
                                             setUploadStatus
                                           }: CustomerStatementsProps): JSX.Element {
  const [uploadStatement, setUploadStatement] = useState<boolean>(false);
  const [mostRecentStatement, setMostRecentStatement] = useState<Statement | undefined>(undefined);
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const [month, setMonth] = useState(
    new Date().toLocaleDateString("en-US", {
      month: "2-digit",
    })
  );
  const currentYear = new Date().toLocaleDateString("en-US", {
    year: "numeric",
  });

  function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  const getMostRecentStatement = async () => {
    fetch(`${process.env.REACT_APP_SERVICES_URL}/v2/nva/statement/customer/${searchResult.dealerFinanceAccountNumber}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.sessionStorage.access_token}`,
        "Content-type": "application/json",
      },
    }).then(async response => {
      const res = await response.json();
      if (response.status === 200 && res.file !== undefined) {
        setMostRecentStatement(res);
      } else {
        setMostRecentStatement(undefined);
      }
    }).catch(error => {
      console.error(`error fetching statement ${error}`);
    })
  }

  const displayMostRecentStatementPDF = () => {
    if (mostRecentStatement !== undefined) {
      const blob = new Blob([base64ToArrayBuffer(mostRecentStatement.file)], {type: "application/pdf"});
      window.open(URL.createObjectURL(blob));
    }
  }

  const submitStatementRequest = async (file: File | undefined) => {
    if (file !== undefined) {
      let formData = new FormData();
      const statementMetaData = {
        statementDate: `${currentYear}-${month}`,
        dealerFinanceAccountNumber: searchResult.dealerFinanceAccountNumber,
      };

      const statementMetaDataBlob = new Blob([JSON.stringify(statementMetaData)], {
        type: "application/json",
      });
      formData.append("statementMetaData", statementMetaDataBlob);
      formData.append("statement", file);
      setUploadStatus("Pending");

      fetch(
        `${process.env.REACT_APP_SERVICES_URL}/v2/nva/statement`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${window.sessionStorage.access_token}`
          },
          body: formData
        }
      ).then((res) => {
          if (res.status === 202) {
            setUploadStatus("Resolved");
            setUploadStatement(!uploadStatement);
            getMostRecentStatement();
          } else {
            setUploadStatus("Rejected");
          }
        }
      )
        .catch
        (error => {
          console.error(`error submitting the statement report ${error}`)
          setUploadStatus("Rejected")
        })
    }
  }

  useEffect(() => {
    getMostRecentStatement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult.dealerFinanceAccountNumber, setMostRecentStatement]);

  return (
    <>
      <div className="flex justify-between items-center leading-9">
        <span className="text-base ford-font-med fds-color__text--black">Customer Statement</span>
        <div className="text-base font-regular cursor-pointer"
             onClick={() => setUploadStatement(!uploadStatement)}>
          {uploadStatement ?
            <span className="text-ford-blue"> <Icon name="clear"/> </span> :
            <span className="text-ford-blue fds-decoration--underline hover:text-ford-blue-secondary">Upload</span>
          }
        </div>
      </div>

      {
        mostRecentStatement ?
          <div className="mt-4 grid grid-cols-latestStatementCustom">
            <FontAwesomeIcon icon="paperclip" className="h-4 mt-1 grid col-start-1"/>
            <div className={"ml-2 grid col-start-2"} onClick={() => displayMostRecentStatementPDF()}>
                    <span
                      className="text-base ford-font-med text-ford-blue-secondary grid row-start-1 cursor-pointer hover:underline underline-offset-4">
                        {mostRecentStatement?.month && monthNames[mostRecentStatement.month - 1] + " " + mostRecentStatement?.year + " Statement"}
                    </span>
            </div>
          </div> :
          <div className="text-base font-light text-ford-gray mt-5">NO STATEMENTS UPLOADED</div>
      }

      {uploadStatement &&
          <>
              <div className="flex mt-2 pb-2 pt-4">
                  <div className={"w-full md:w-1/2"}>
                      <div className="mb-6 text-base text-ford-gray font-light">
                          <span>To upload a new customer statement, please select the statement month, upload the file, and save.</span>
                      </div>
                      <label htmlFor="month" className="block ford-font-med text-ford-gray-third text-sBaseCustom">
                          Month
                      </label>
                      <Select
                          id="month"
                          className={["w-full", "border", "border-black", "rounded", "p-4", "text-base", "text-ford-gray", "text-base", "caret-override"]}
                          options={monthOptions}
                          defaultValue={month}
                          onChange={(newMonth) => setMonth(newMonth)}
                      />
                  </div>
              </div>
              <div className="mt-4 mb-12 pb-1">
                  <DropZone
                      label={"Upload new file:"}
                      uploadRequestStatus={uploadStatus}
                      uploadButtonPrimary={true}
                      onClick={submitStatementRequest}
                      uploadButton={true}
                      type={"Statement"}
                  />
              </div>
          </>
      }

    </>
  )
}
