import Dropzone, {FileRejection} from "react-dropzone";
import {useEffect, useState} from "react";
import {PrimaryButton} from "@cxc/react-fds-components";
import {Status} from "@fmcc/common-hooks/dist/hooks/useFetch/useFetch";

type DropZoneProps = {
  label: string;
  onClick: (document: File | undefined) => void;
  uploadRequestStatus: Status;
  uploadButton?: boolean;
  uploadButtonPrimary?: boolean;
  type: string
};

const convertBytesToMb = (bytes: number | undefined) => {
  if (!bytes) return "0"
  const megaBytes = bytes / 1024 / 1024;
  if (megaBytes < 0.01) {
    return "0.01";
  }
  return megaBytes.toFixed(2);
};

export default function DropZone({
                                   label,
                                   onClick,
                                   uploadRequestStatus,
                                   uploadButton = true,
                                   uploadButtonPrimary = false,
                                   type
                                 }: DropZoneProps): JSX.Element {
  const [fileUploadErrors, setFileUploadErrors] = useState<string>("");
  const [document, setDocument] = useState<File>()

  const removeFile = () => {
    setDocument(undefined)
  };

  useEffect(() => {
    if (uploadRequestStatus === "Resolved") setDocument(undefined);
  }, [uploadRequestStatus]);


  const fileDropHandler = async (files: File[]) => {
    if (files.length > 1) {
      switch (type) {
        case "GDMS":
          setFileUploadErrors("You may only upload one GDMS report.")
          break;
        case "Statement":
          setFileUploadErrors("You may only upload one Statement PDF file.")
          break;
        default:
          break;
      }
    } else if (files.length === 1) {
      setDocument(files[0]);
      setFileUploadErrors("")
    }
  }

  const fileDropRejectedHandler = (fileRejections: FileRejection[]) => {
    fileRejections.forEach((fileRejection) => {
      fileRejection.errors.forEach((fileRejectErr) => {
        if (fileRejectErr.code === "file-invalid-type") {
          setFileUploadErrors("File Type is Invalid we only accept excel sheet");
        } else if (fileRejectErr.message) {
          setFileUploadErrors(fileRejectErr.message);
        }
      });
    });
  };

  return (
    <>
      <span className="font-medium text-ford-gray text-base">{label}</span>
      <Dropzone onDrop={fileDropHandler}
                accept={{
                  "application/pdf": [".pdf"],
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"]
                }}
                onDropRejected={fileDropRejectedHandler}>
        {({getRootProps, getInputProps}) => (
          <div className="mt-4 mb-10 font-medium">
            <div
              {...getRootProps({
                className: "flex flex-col h-40 w-full border-2 border-dashed border-ford-gray-second rounded-md items-center justify-center",
              })}
            >
              <input data-testid="dropzone-input" {...getInputProps()} />
              <span className="text-base text-ford-gray">Drag and drop files here or </span>
              <br/>
              <p className="text-ford-blue">Select file</p>
            </div>
            {document &&
                <aside className="text-xs">
                    <ul>
                        <li className="text-xs ford-font-reg text-ford-gray p-2">
                          {document?.name} - {convertBytesToMb(document?.size)} MB -{" "}
                            <span
                                className="text-xs underline inline cursor-pointer text-ford-blue-secondary"
                                onClick={() => removeFile()}
                            >
                                Remove File
                            </span>
                        </li>
                    </ul>
                </aside>}
            {fileUploadErrors !== "" && (
              <span className="text-red-600 text-sm">
               * {fileUploadErrors}
              </span>
            )}
          </div>
        )}
      </Dropzone>
      {uploadButton &&
          <div className="float-right">
              <PrimaryButton title="Upload"
                             outlined={uploadButtonPrimary}
                             onClick={() => onClick(document)}
                             disabled={!document}
                             loading={uploadRequestStatus === "Pending"}/>
          </div>
      }
    </>
  );
}
