import { UseFormRegisterReturn } from "react-hook-form";

export type Option = {
  display: string;
  value: string | number;
};

export type SelectProps = {
  options: Option[];
  onChange: (value: string) => void;
  disabled?: boolean;
  className?: string[];
  defaultValue?: string;
  id?: string;
  register?: UseFormRegisterReturn;
};

export default function Select({
  options,
  onChange,
  disabled = false,
  className = [],
  defaultValue,
  id,
  register,
}: SelectProps): JSX.Element {
  return (
    <select
      {...register}
      id={id}
      defaultValue={defaultValue}
      className={`${className.join(" ")}`}
      disabled={disabled}
      onChange={(event) => onChange(event.target.value)}
    >
      {options.map(({ display, value }) => (
        <option key={value} value={value}>
          {display}
        </option>
      ))}
    </select>
  );
}
