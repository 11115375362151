import {useFetch} from "@fmcc/common-hooks";
import {useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {PrimaryButton} from "@cxc/react-fds-components";
import Select, {Option} from "../../../components/common/select/Select";
import {UploadRequestStatusIcon} from "../../../components/common/upload-request-status-icon/UploadRequestStatusIcon";

type FormValues = {
  dealerFinanceAccountNumber: string;
  statementFile: FileList;
};

export const monthOptions: Option[] = [
  {display: "January", value: "01"},
  {display: "February", value: "02"},
  {display: "March", value: "03"},
  {display: "April", value: "04"},
  {display: "May", value: "05"},
  {display: "June", value: "06"},
  {display: "July", value: "07"},
  {display: "August", value: "08"},
  {display: "September", value: "09"},
  {display: "October", value: "10"},
  {display: "November", value: "11"},
  {display: "December", value: "12"},
];

export default function StatementUpload(): JSX.Element {
  const currentYear = new Date().toLocaleDateString("en-US", {
    year: "numeric",
  });

  const [uploadRequestStatus, uploadRequest, , uploadRequestStatusCode] =
    useFetch<void>();
  const [month, setMonth] = useState(
    new Date().toLocaleDateString("en-US", {
      month: "2-digit",
    })
  );
  const [year, setYear] = useState(currentYear);
  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: {errors},
  } = useForm<FormValues>();
  const yearOptions: Option[] = [
    {
      display: `${parseInt(currentYear) - 1}`,
      value: `${parseInt(currentYear) - 1}`,
    },
    {display: `${parseInt(currentYear)}`, value: `${parseInt(currentYear)}`},
    {
      display: `${parseInt(currentYear) + 1}`,
      value: `${parseInt(currentYear) + 1}`,
    },
  ];

  useEffect(() => {
    if (uploadRequestStatusCode === 404) {
      setError("dealerFinanceAccountNumber", {
        type: "custom",
        message:
          "The entered account number is invalid. Please input the account number again.",
      });
    } else {
      clearErrors("dealerFinanceAccountNumber");
    }
    if (uploadRequestStatus === "Resolved") {
      reset();
    }
  }, [
    reset,
    uploadRequestStatus,
    uploadRequestStatusCode,
    setError,
    clearErrors,
  ]);

  const submitStatementFile: SubmitHandler<FormValues> = (data) => {
    let formData = new FormData();
    const statementMetaData = {
      statementDate: `${year}-${month}`,
      dealerFinanceAccountNumber: data.dealerFinanceAccountNumber,
    };

    const statementMetaDataBlob = new Blob([JSON.stringify(statementMetaData)], {
      type: "application/json",
    });

    formData.append("statementMetaData", statementMetaDataBlob);
    formData.append("statement", data.statementFile[0]);

    uploadRequest(`${process.env.REACT_APP_SERVICES_URL}/v2/nva/statement`, {
      body: formData,
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.sessionStorage.access_token}`,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(submitStatementFile)}>
      <label
        htmlFor="dealerFinanceAccountNumberInput"
        className="m-2 block ford-font-med"
      >
        Dealer Finance Account Number
      </label>
      <input
        {...register("dealerFinanceAccountNumber")}
        id="dealerFinanceAccountNumberInput"
        type="text"
        className="m-2 w-full border p-2"
        required
      />
      {errors.dealerFinanceAccountNumber && (
        <p className="text-left mx-2 text-red-500 text-sm">
          {errors.dealerFinanceAccountNumber.message}
        </p>
      )}
      <label htmlFor="statementFile" className="m-2 block ford-font-med">
        File
      </label>
      <input
        {...register("statementFile")}
        type="file"
        className="m-2 border-dashed border-2 p-2 w-full"
        required
      />
      <div className="flex pb-4">
        <div className={"w-full md:w-1/2 pr-4"}>
          <label htmlFor="month" className="m-2 block ford-font-med">
            Month
          </label>
          <Select
            id="month"
            className={["m-2", "w-full", "border", "p-2", ""]}
            options={monthOptions}
            defaultValue={month}
            onChange={(newMonth) => setMonth(newMonth)}
          />
        </div>
        <div className={"w-full md:w-1/2"}>
          <label htmlFor="year" className="m-2 block ford-font-med">
            Year
          </label>
          <Select
            id="year"
            className={["m-2", "w-full", "border", "p-2", ""]}
            options={yearOptions}
            defaultValue={year}
            onChange={(newYear) => setYear(newYear)}
          />
        </div>
      </div>
      <div className="fds-app pl-2 w-1/3">
        <PrimaryButton
          className="p-1.5 mb-10"
          type={"submit"}
          title={"Upload"}
          loading={uploadRequestStatus === "Pending"}
        />
      </div>
      <div>
        {UploadRequestStatusIcon(uploadRequestStatus)}
      </div>
    </form>
  );
}
