import {useState} from "react";
import errorIcon from "../../../assets/Error_triangle_red.svg";

type Props = {
  message: string;
};

export function SuccessToast({message}: Props): JSX.Element {
  const [closed, setClosed] = useState(false)
  const [visible, setVisible] = useState<boolean>(true);

  setTimeout(() => {
    setVisible(false);
  }, 7000)

  const closeToast = () => {
    setClosed(true)
  }

  return (
    <div className={closed || !visible ? "hidden" : ""}>
      <div
        className="flex flex-nowrap w-full mx-auto justify-between items-center overflow-hidden bg-white border border-ford-green max-w pt-2 pb-2">
        <div className={"flex items-center"}>
          <svg
            className="w-6 text-green-600 fill-current ml-6"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"/>
          </svg>
          <span className="text-sm text-black ml-4">
            Success: {message}.
          </span>
        </div>
        <div className="mr-4 items-center">
          <button
            onClick={() => closeToast()}
            type="button"
            className="bg-white
             text-black hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span className="sr-only">Close menu</span>
            X
          </button>
        </div>
      </div>
    </div>
  );
}

export function ErrorToast({message}: Props): JSX.Element {
  const [closed, setClosed] = useState(false)
  const closeToast = () => {
    setClosed(true)
  }

  const [visible, setVisible] = useState<boolean>(true);

  setTimeout(() => {
    setVisible(false);
  }, 7000)

  return (
    <div className={closed || !visible ? "hidden" : ""}>
      <div
        className="flex flex-nowrap w-full mx-auto justify-between items-center overflow-hidden bg-white border border-error-red max-w pt-2 pb-2">
        <div className={"flex items-center ml-4"}>
          <img src={errorIcon} alt={"X"} height="30px" width="30px" color={"#D62D0A"}/>
          <span className="text-base text-black ml-4">
            Error: {message}.
          </span>
        </div>
        <div className="mr-4 items-center">
          <button
            onClick={() => closeToast()}
            type="button"
            className="bg-white
             text-black hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span className="sr-only">Close menu</span>
            X
          </button>
        </div>
      </div>
    </div>
  );
}
