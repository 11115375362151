export const formatMoney = (amount: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);

export const formatIsoDateString = (isoDateString: string) => formatDate(new Date(isoDateString));

export const formatDate = (date: Date) => date.toLocaleDateString("en-US", {
  year: "numeric",
  month: "long",
  day: "2-digit",
  timeZone: "UTC"
});
