import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

export const ADFS_BASE_URL = process.env.REACT_APP_ADFS_BASE_URL as string;
const ADFS_CLIENT_ID = process.env.REACT_APP_ADFS_CLIENT_ID as string;
const ADFS_RESOURCE = process.env.REACT_APP_ADFS_RESOURCE_URI as string;
export const ADFS_REDIRECT_URI = process.env
  .REACT_APP_ADFS_REDIRECT_URI as string;
export const ADFS_LOGIN_PAGE_URL = `${ADFS_BASE_URL}/authorize/wia?response_type=code id_token&client_id=${ADFS_CLIENT_ID}&resource=${ADFS_RESOURCE}&redirect_uri=${ADFS_REDIRECT_URI}`;
export type AuthStatus =
  | "idle"
  | "redirecting"
  | "authorizing"
  | "authorized"
  | "not_authorized";

export function useAdfsAuthentication(): AuthStatus {
  const [authStatus, setAuthStatus] = useState<AuthStatus>("idle");
  const location = useLocation();

  useEffect(() => {
    if (hasValidAccessToken()) {
      setAuthStatus("authorized");
    } else {
      (async () => {
        const queryParams = getQueryParamsFromUrl();
        const adfsCode = queryParams.get("code");
        const idtoken = queryParams.get("id_token");
        if (adfsCode && idtoken) {
          setAuthStatus("authorizing");
          await exchangeCodeForAccessToken(adfsCode, idtoken);
          setAuthStatus(hasValidAccessToken() ? "authorized" : "not_authorized")
        } else {
          setAuthStatus('redirecting')
        }
      })();
    }
  }, [location]);

  return authStatus;
}

const exchangeCodeForAccessToken = async (code: string, idtoken: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVICES_URL}/v1/authorization/jwt/${code}`,
    {
      method: "GET",
    }
  );

  if (!response.ok) {
    window.sessionStorage.clear();

    return;
  }
  const jsonResponse = await response.json();
  window.sessionStorage.setItem("access_token", jsonResponse.accessToken);
  window.sessionStorage.setItem("user_role", jsonResponse.userRole);
  window.sessionStorage.setItem("idToken", idtoken);
  window.location.replace(window.location.origin + window.location.pathname);
};

const hasValidAccessToken = () => {
  const accessToken = window.sessionStorage.getItem("access_token");
  return !!(accessToken && !isAccessTokenExpired(accessToken));

};

const isAccessTokenExpired = (accessToken: string) => {
  try {
    const expirationInSeconds = JSON.parse(
      atob(accessToken.split(".")[1])
    )?.exp;
    const expirationInMillis = expirationInSeconds * 1000;

    const expirationTime = new Date(expirationInMillis);
    const currentTime = new Date();
    return currentTime > expirationTime;
  } catch (e) {
    return false;
  }
};
export const getQueryParamsFromUrl = (): URLSearchParams => {
  const url = window.location.href;
  const newUrl = url.replace(/#/, "?");
  const queryParams = newUrl.split("/")[3];
  return new URLSearchParams(queryParams);
};

const asyncSessionStorage = {
  getItem: async function (key: string) {
        await Promise.resolve();
        return sessionStorage.getItem(key);
    }
  };

export const adfsLogout = async () => {
    asyncSessionStorage.getItem("idToken")
    .then((tokenId) => {
      window.sessionStorage.clear();
      window.location.replace(`${ADFS_BASE_URL}/logout?post_logout_redirect_uri=${ADFS_REDIRECT_URI}&id_token_hint=${tokenId}`);
      });
  };
