import {Tab, Tabs} from "@cxc/react-fds-components";
import RequestApprovalDashboard
  from "../../../pages/business-operations/request-approval-dashboard/RequestApprovalDashboard";
import RequestProcessingDashboard
  from "../../../pages/business-operations/request-processing-dashboard/RequestProcessingDashboard";
import GdmsReportDashboard from "../../../pages/business-operations/gdms-report-dashboard/GdmsReportDashboard";
import CustomerInformationDashboard
  from "../../../pages/business-operations/customer-information-dashboard/CustomerInformationDashboard";

const lastActiveTab = parseInt(window.sessionStorage.activeDashboardTab);

export default function Dashboard() {
  const setActiveTab = (tabIndex: string) => {
    if ((tabIndex === "3" || tabIndex === "4"))
      window.sessionStorage.setItem("activeDashboardTab", "1");
    else
      window.sessionStorage.setItem("activeDashboardTab", tabIndex);
  };

  const getTabs = () => {
    if (window.sessionStorage.user_role === "ROLE_CO" || window.sessionStorage.user_role === "ROLE_ADMIN") {
      return (
        <Tabs
          onTabChange={(index) => {
            setActiveTab(`${index}`);
          }}
          defaultActiveTabIndex={lastActiveTab}
          className="w-full"
        >
          <Tab label="Request Approval">
            <RequestApprovalDashboard/>
          </Tab>
          <Tab label="Request Processing">
            <RequestProcessingDashboard/>
          </Tab>
          <Tab label="Customer Information">
            <div className="flex justify-center">
              <CustomerInformationDashboard/>
            </div>
          </Tab>
          <Tab label="GDMS Report">
            <div className="flex justify-center">
              <GdmsReportDashboard/>
            </div>
          </Tab>
        </Tabs>
      )
    } else {
      return (
        <Tabs
          onTabChange={(index) => {
            setActiveTab(`${index}`);
          }}
          defaultActiveTabIndex={lastActiveTab}
          className="w-full"
        >
          <Tab label="Request Approval">
            <RequestApprovalDashboard/>
          </Tab>
        </Tabs>
      )
    }
  }

  return (
    <div>
      <h3>
        <p className="text-4xl py-8 fordngbs-antenna-medium text-left ml-4">
          Charging Loan Operations
        </p>
      </h3>
      <div className="fds-app w-full text-left">
        {getTabs()}
      </div>
    </div>
  );
}