import {CustomerByDealerFinanceAccount} from "./CustomerInformationDashboard";

import CustomerStatements from "./CustomerStatements";
import CustomerBankAccount from "./CustomerBankAccount";
import React, {useState} from "react";
import {UploadRequestStatusIcon} from "../../../components/common/upload-request-status-icon/UploadRequestStatusIcon";
import {Status} from "@fmcc/common-hooks/dist/hooks/useFetch/useFetch";
import {faHandHoldingDollar} from "@fortawesome/free-solid-svg-icons/faHandHoldingDollar";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons/faCreditCard";
import {faPaperclip} from '@fortawesome/free-solid-svg-icons'
import {faPenToSquare, far} from "@fortawesome/free-regular-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core'

library.add(far, faPaperclip, faPenToSquare, faCreditCard, faHandHoldingDollar)
export type CustomerSearchResultProp = {
  searchResult: CustomerByDealerFinanceAccount
}

export default function CustomerSearchResultPanel({searchResult}: CustomerSearchResultProp): JSX.Element {
  //const [status, send, response, statusCode] = useFetch();
  const [uploadStatus, setUploadStatus] = useState<Status>("NotAsked");

  return (
    <div className="fds-app">
      <div>
        <p className="pb-2">
          <span className="text-base ford-font-light text-ford-gray">Search Result:</span>
        </p>
      </div>
      <div className="border border-gray p-6 shadow-lg">
        <span className="text-2xl ford-font-med text-ford-blue">{searchResult.businessName}</span>
        <div className="leading-8 fmc-mb-3">
          <span
            className="text-xsCustom ford-font-light text-ford-gray">Dealer Finance Account Number: {searchResult.dealerFinanceAccountNumber}</span>
        </div>
        <CustomerBankAccount searchResult={searchResult}/>
        <hr className={"my-6 leading-12 border-t-2 border-gray-300 "}/>
        <CustomerStatements uploadStatus={uploadStatus} setUploadStatus={setUploadStatus} searchResult={searchResult}/>
      </div>

      <div className="mt-8">
        {UploadRequestStatusIcon(uploadStatus)}
      </div>
    </div>
  )
}
