import logo from "../../../assets/FordCredit_Blue_v1.svg";

export default function Header(): JSX.Element {
  return (
    <div>
      <div className="fds-app w-full bg-gray-200 h-20 flex justify-between fordngbs-antenna-re border-none">
        <img alt="ford-credit-logo" className="h-20" src={logo} />
      </div>
    </div>
  );
}
